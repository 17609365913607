<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="date"
            headerText="Datum"
            textAlign="left"
          ></e-column>
          <e-column
            field="start"
            headerText="Beginn"
            textAlign="right"
          ></e-column>
          <e-column
            field="end"
            headerText="Ende"
            textAlign="right"
          ></e-column>
          <e-column
            field="category"
            headerText="Veranstaltungsreihe"
            textAlign="left"
          ></e-column>
          <e-column
            field="label"
            headerText="Bezeichnung"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
          <e-column
            field="badge_type"
            headerText="<i class='fas fa-ticket-alt' data-toggle='tooltip' data-placement='top' title='Eintrittskartentyp'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
          <e-column
            field="show_host"
            headerText="<i class='fas fa-user-check' data-toggle='tooltip' data-placement='top' title='Zeige vermittelnden Händler auf Eintrittskarte der Besucher'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
          <e-column
            field="show_client_in_modal"
            headerText="<i class='far fa-window-maximize' data-toggle='tooltip' data-placement='top' title='Aussteller: Kundendaten in Dialog anzeigen'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
          <e-column
            field="show_client_in_datasheet"
            headerText="<i class='far fa-file-alt' data-toggle='tooltip' data-placement='top' title='Aussteller: Kundendaten in Datenblatt anzeigen'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
          <e-column
            field="show_client_in_packing_list"
            headerText="<i class='far fa-list-alt' data-toggle='tooltip' data-placement='top' title='Aussteller: Kundendaten in Musterpackliste anzeigen'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Event -->
    <mdb-modal
      centered
      size="xl"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title v-if="modalEdit">Veranstaltung bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Veranstaltung hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="4" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="calendar-alt" class="blue-text" /> Eigenschaften</legend>
              <input type="hidden" v-model="model.id">
              <mdb-select
                label="Veranstaltungsreihe"
                class=""
                v-model="model.category"
                ref="category"
              />
              <mdb-input
                label="Neue Veranstaltungsreihe"
                type="text"
                class="mb-3 mt-3"
                v-model="model.newCategoryLabel"
                v-show="newCategory === true"
                required
              />
              <mdb-input
                label="Bezeichnung"
                type="text"
                class="mb-3 mt-3"
                v-model="model.label"
                required
              />
              <mdb-date-picker-2 v-model="model.date" label="Datum" title="Bitte wählen" :options="datePickerOptions" />
              <mdb-time-picker id="timePickerStart" placeholder="Bitte wählen Sie eine Uhrzeit" label="Beginn" :hours-format="24" v-model="model.start" />
              <mdb-time-picker id="timePickerEnd" placeholder="Bitte wählen Sie eine Uhrzeit" label="Ende" :hours-format="24" v-model="model.end" />
            </mdb-col>  
            <mdb-col md="4" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="map-marked-alt" class="blue-text" /> Ort</legend>
              <mdb-input
                label="Straße"
                type="text"
                class="mb-3 mt-3"
                v-model="model.street"
              />
              <mdb-input
                label="PLZ"
                type="text"
                class="mb-3 mt-3"
                v-model="model.zip"
              />
              <mdb-input
                label="Stadt"
                type="text"
                class="mb-3 mt-3"
                v-model="model.city"
              />
              <mdb-input
                label="Region"
                type="text"
                class="mb-3 mt-3"
                v-model="model.region"
              />
              <mdb-input
                label="Land"
                type="text"
                class="mb-3 mt-3"
                v-model="model.country"
              />
            </mdb-col>
            <mdb-col md="4" class="mb-3">
              <legend><mdb-icon icon="cog" class="blue-text" /> Einstellungen</legend>
              <mdb-select
                label="Präposition"
                class=""
                v-model="model.preposition"
                ref="preposition"
              />
              <mdb-select
                label="Eintrittskarte: Typ"
                class=""
                v-model="model.badge_type"
                ref="badge_type"
              />
              <mdb-select
                label="Eintrittskarte: Vermittelnden Händler anzeigen"
                class=""
                v-model="model.show_host"
                ref="show_host"
              />
              <mdb-select
                label="Aussteller: Kundendaten in Dialog anzeigen"
                class=""
                v-model="model.show_client_in_modal"
                ref="show_host"
              />
              <mdb-select
                label="Aussteller: Kundendaten in Datenblatt anzeigen"
                class=""
                v-model="model.show_client_in_datasheet"
                ref="show_host"
              />
              <mdb-select
                label="Aussteller: Kundendaten in Musterpackliste anzeigen"
                class=""
                v-model="model.show_client_in_packing_list"
                ref="show_host"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" v-if="modalEdit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Hinzufügen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Event -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Veranstaltung löschen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Veranstaltungen wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="deleteRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Edit, Toolbar, Resize, Search, Sort, Group } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect,
  mdbDatePicker2,
  mdbTimePicker
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Events',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    mdbDatePicker2,
    mdbTimePicker
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        date: '',
        start: '',
        end: '',
        category: [],
        newCategoryLabel: '',
        preposition: [],
        label: '',
        badge_type: [],
        show_host: [],
        show_client_in_modal: [],
        show_client_in_datasheet: [],
        show_client_in_packing_list: [],
        street: '',
        zip: '',
        city: '',
        region: '',
        country: ''
      },
      newCategory: false,
      selectedRecords: [],
      showModal: false,
      modalEdit: false,
      showDelete: false,
      deleteList: [],
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_events.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Mitarbeiter hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Einen Mitarbeiter bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Einen oder mehrere Mitarbeiter löschen', prefixIcon: 'e-delete', id: 'deleteRecord' },
        'Search'
      ],
      filterOptions: {
        type: 'Menu'
      },
      filter: {
        type: 'CheckBox'
      },
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      sortSettings: {
        columns: [
          {
            field: 'date',
            direction: 'Descending'
          }
        ]
      },
      groupSettings: { 
        captionTemplate: "<div>${format(data)}</div>",
        columns: []
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      },
      datePickerOptions: {
        format: 'DD.MM.YYYY',
        week: ['Sa', 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr'],
        month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        placeholder: 'Bitte wählen Sie ein Datum',
        buttons: {
          ok: 'Ok',
          cancel: 'Abbrechen',
          clear: 'Heute'
        }
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
  },
  computed: {
    eventCategorySelected() {
      return this.model.category;
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    },
    eventCategorySelected: {
      handler: function () {
        if ('0' in this.model.category && this.model.category[0].selected === true) {
          this.newCategory = true
        } else {
          this.newCategory = false
        }
      }
    }
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['date', 'start', 'end', 'category'])
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'badge_type') {
        if (args.data['badge_type'] === 0) {
          args.cell.classList.add('status-circle', 'status-blue')
        } else if (args.data['badge_type'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
      if (args.column.field === 'show_host') {
        if (args.data['show_host'] === 0) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['show_host'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
      if (args.column.field === 'show_client_in_modal') {
        if (args.data['show_client_in_modal'] === 0) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['show_client_in_modal'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
      if (args.column.field === 'show_client_in_datasheet') {
        if (args.data['show_client_in_datasheet'] === 0) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['show_client_in_datasheet'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
      if (args.column.field === 'show_client_in_packing_list') {
        if (args.data['show_client_in_packing_list'] === 0) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['show_client_in_packing_list'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_event.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.deleteList = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_event.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.label !== '') {
        if (this.newCategory === false || (this.newCategory === true && this.model.newCategoryLabel !== '' && this.model.newCategoryLabel !== undefined)) {
          this.loading = true
          var url = ''
          if (this.modalEdit === true) {
            url = 'edit_event.php'
          } else {
            url = 'add_event.php'
          }
          axios({
            method: 'post',
            url: url,
            data: this.model
          }).then(response => {
            this.loading = false
            if (response.data.success === false) {
              alert(response.data.error)
            } else {
              if (this.modalEdit === true) {
                var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
                this.$refs.grid.updateRow(rowIndex, this.model)
              } else {
                this.$refs.grid.refresh()
              }
              this.showModal = false
              bus.$emit('eventReload')
              this.resetModel()
            }
          }).catch(error => {
            this.loading = false
            alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
          })
        }
      }
    },
    deleteRecord () {
      this.loading = true
      var url = 'delete_event.php'
      axios({
        method: 'post',
        url: url,
        data: this.deleteList
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
          bus.$emit('eventReload')
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.date = ''
      this.model.start = ''
      this.model.end = ''
      this.model.category = []
      this.model.newCategoryLabel = ''
      this.model.preposition = [
        { text: 'für die / an der / zur', value: 0, selected: true },
        { text: 'für den / am / zum', value: 1, selected: false }
      ]
      this.model.label = ''
      this.model.badge_type = [
        { text: 'Papier', value: 1, selected: true },
        { text: 'Plastik', value: 0, selected: false }
      ]
      this.model.show_host = [
        { text: 'Ja', value: 1, selected: true },
        { text: 'Nein', value: 0, selected: false }
      ]
      this.model.show_client_in_modal = [
        { text: 'Ja', value: 1, selected: false },
        { text: 'Nein', value: 0, selected: true }
      ]
      this.model.show_client_in_datasheet = [
        { text: 'Ja', value: 1, selected: false },
        { text: 'Nein', value: 0, selected: true }
      ]
      this.model.show_client_in_packing_list = [
        { text: 'Ja', value: 1, selected: false },
        { text: 'Nein', value: 0, selected: true }
      ]
      this.model.street = ''
      this.model.zip = ''
      this.model.city = ''
      this.model.region = ''
      this.model.country = ''

      axios.post('select_events.php', {
        eventCategory: '',
        eventYear: '',
        event: '',
        mode: 'add'
      }).then(response => {
        this.model.category = response.data.eventCategoryOptions
      })
    }
  },
  provide: {
    grid: [Edit, Toolbar, Resize, Search, Sort, Group]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  switch (value.field) {
    case 'test':
      return value.key + ' ' + x
    default:
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: bold;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

#descriptionEditor {
  height: 100px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

.e-search-icon {
  font-size: 20px !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 295px !important;
}

.e-search input {
  font-size: 17px !important;
}

.picker--opened .picker__holder {
  background: none !important;
}

.select-dropdown label {
  margin-right: 0px !important;
  max-width: 100% !important;
}
</style>