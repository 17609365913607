<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="segment"
            headerText="Gruppe"
            textAlign="Left"
            :disableHtmlEncode="false"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="status"
            headerText="<i class='fas fa-check' data-toggle='tooltip' data-placement='top' title='Rabatt gesetzt'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="company"
            headerText="Firma"
            textAlign="Left"
          ></e-column>
          <e-column
            field="city"
            headerText="Stadt"
            textAlign="Left"
          ></e-column>
          <e-column
            field="memo"
            headerText="<i class='fas fa-comment' data-toggle='tooltip' data-placement='top' title='Kommentar'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="consultant"
            headerText="Mitarbeiter"
            textAlign="Left"
            :disableHtmlEncode="false"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="discount"
            headerText="Grundrabatt"
            textAlign="Left"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="discount_special"
            headerText="Sonderrabatt"
            textAlign="Left"
            :allowSorting="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Discount -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>{{ model.company }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="user" class="blue-text" /> Rabatte</legend>
              <ul class="pl-4 text-muted">
                <li><small>Rabatte gelten für Produkte, Werbeanbringungen und Grundkosten.</small></li>
                <li><small>Rabatte werden für die jeweilige Position nur berechnet, wenn kein Handelspreis hinterlegt ist.</small></li>
                <li><small>Rabatte werden immer vom jeweiligen Industriepreis aus berechnet.</small></li>
              </ul>
              <input type="hidden" v-model="model.id">
              <mdb-input
                label="Grundrabatt (%)"
                type="text"
                class="mb-3 mt-4"
                v-model="model.discount"
                required
              />
              <mdb-input
                label="Sonderrabatt (%)"
                type="text"
                class="mb-3 mt-3"
                v-model="model.discount_special"
                required
              />
            </mdb-col>
            <mdb-col md="6" class="mb-3">
              <legend><mdb-icon icon="cog" class="blue-text mt-4" /> Einstellungen</legend>
              <mdb-select
                label="Gruppe"
                class="my-0 mt-3 mb-0"
                v-model="model.segment"
                ref="segment"
                validation
                search
              />
              <mdb-select
                label="Mitarbeiter"
                class="my-0 mt-3 mb-0"
                v-model="model.consultant"
                ref="consultant"
                validation
                search
              />
              <legend><mdb-icon icon="comment" class="blue-text" /> Anmerkung</legend>
              <vue-editor
                id="memoEditor"
                v-model="model.memo"
                :editor-toolbar="editorToolbar"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Discount -->
    <!-- Modal: Segment -->
    <mdb-modal :show="showSegment" @close="showSegment = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Gruppe zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Händler mit folgender Gruppe verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Gruppe"
                    class="mt-3"
                    v-model="model.segment"
                    ref="bulk_segment"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.company">
                      {{ record.company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showSegment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="segmentEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Segment -->
    <!-- Modal: Consultant -->
    <mdb-modal :show="showConsultant" @close="showConsultant = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Mitarbeiter zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Händler mit folgendem Mitarbeiter verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Mitarbeiter"
                    class="mt-3"
                    v-model="model.consultant"
                    ref="bulk_consultant"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.company">
                      {{ record.company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showConsultant = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="consultEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Consultant -->
    <!-- Modal: Discount -->
    <mdb-modal :show="showDiscount" @close="showDiscount = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Grundrabatt zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den gewählten Händlern folgenden Grundrabatt zuweisen?</strong>
            </p>
            <ul class="pl-4 text-muted">
              <li><small>Rabatte gelten für Produkte, Werbeanbringungen und Grundkosten.</small></li>
              <li><small>Rabatte werden für die jeweilige Position nur berechnet, wenn kein Handelspreis hinterlegt ist.</small></li>
              <li><small>Rabatte werden immer vom jeweiligen Industriepreis aus berechnet.</small></li>
            </ul>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-input
                    label="Grundrabatt (%)"
                    type="text"
                    class="mb-3 mt-4"
                    v-model="model.discount"
                    required
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.company">
                      {{ record.company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showDiscount = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="discountEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Discount -->
    <!-- Modal: Discount Special -->
    <mdb-modal :show="showDiscountSpecial" @close="showDiscountSpecial = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Sonderrabatt zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den gewählten Händlern folgenden Sonderrabatt zuweisen?</strong>
            </p>
            <ul class="pl-4 text-muted">
              <li><small>Rabatte gelten für Produkte, Werbeanbringungen und Grundkosten.</small></li>
              <li><small>Rabatte werden für die jeweilige Position nur berechnet, wenn kein Handelspreis hinterlegt ist.</small></li>
              <li><small>Rabatte werden immer vom jeweiligen Industriepreis aus berechnet.</small></li>
            </ul>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-input
                    label="Sonderrabatt (%)"
                    type="text"
                    class="mb-3 mt-4"
                    v-model="model.discount_special"
                    required
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.company">
                      {{ record.company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showDiscountSpecial = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="discountSpecialEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Discount Special -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Edit, Toolbar, Resize, Search, Sort, Group } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Discounts',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    VueEditor
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        company: '',
        segment: [],
        consultant: '',
        discount: '0,00',
        discount_special: '0,00',
        memo: ''
      },
      action: {
        selection: []
      },
      eventCategory: localStorage.getItem('eventCategory'),
      eventYear: localStorage.getItem('eventYear'),
      event: localStorage.getItem('event'),
      username: localStorage.getItem('username'),
      selectedRecords: [],
      showModal: false,
      modalEdit: false,    
      showSegment: false,
      showConsultant: false,
      showDiscount: false,
      showDiscountSpecial: false,
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_discounts.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Bearbeiten', tooltipText: 'Händler bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { type: 'Separator' },
        { text: 'Gruppe', tooltipText: 'Händlern eine Gruppe zuweisen', prefixIcon: 'e-icon-bookmark', id: 'segmentRecord' },
        { text: 'Mitarbeiter', tooltipText: 'Händlern einen Mitarbeiter zuweisen', prefixIcon: 'e-icon-resource', id: 'consultRecord' },
        { type: 'Separator' },
        { text: 'Grundrabatt', tooltipText: 'Händlern einen Grundrabatt zuweisen', prefixIcon: 'e-icon-chevron-down', id: 'discountRecord' },
        { text: 'Sonderrabatt', tooltipText: 'Händlern einen Sonderrabatt zuweisen', prefixIcon: 'e-icon-chevron-down-double', id: 'discountSpecialRecord' },
        { type: 'Separator' },
        'Search'
      ],
      filterOptions: {
        type: 'Menu'
      },
      filter: {
        type: 'CheckBox'
      },
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      sortSettings: {
        columns: [
          {
            field: 'company',
            direction: 'Ascending'
          }
        ]
      },
      groupSettings: { 
        captionTemplate: "<div>${format(data)}</div>",
        columns: []
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      },
      statusColumnAttributes : {
        class: 'status-column'
      }
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    if (localStorage.eventCategory) {
      this.eventCategory = localStorage.eventCategory
    }
    if (localStorage.eventYear) {
      this.eventYear = localStorage.eventYear
    }
    if (localStorage.event) {
      this.event = localStorage.event
    }
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountSpecialRecord'], false)
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountSpecialRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['discountSpecialRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    }
  },
  methods: {
    eventChange: function () {
      this.$refs.grid.refresh()
    },
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['segment', 'consultant', 'discount', 'discount_special'])
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
        //console.log(this.groupSettings.columns)
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'status') {
        if (args.data['status'] === 1) {
          args.cell.classList.add('status-circle', 'status-green')
        } else {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'editRecord') {
        axios.get('get_discount.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'segmentRecord') {
        this.action.selection = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showSegment = true
      }
      if (args.item.id === 'consultRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showConsultant = true
      }
      if (args.item.id === 'discountRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDiscount = true
      }
      if (args.item.id === 'discountSpecialRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDiscountSpecial = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_discount.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.company !== '' && this.model.title !== '' && this.model.first_name !== '' && this.model.last_name !== '' && this.model.email !== '') {
        this.loading = true
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_discount.php'
        } else {
          url = 'add_discount.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
            //this.$refs.grid.clearRowSelection()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    segmentEntry () {
      this.loading = true
      var url = 'segment_discount.php'
      const payload = {
        ids: this.action.selection,
        segment: this.model.segment
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showSegment = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    consultEntry () {
      this.loading = true
      var url = 'consult_discount.php'
      const payload = {
        ids: this.action.selection,
        consultant: this.model.consultant
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showConsultant = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    discountEntry () {
      this.loading = true
      var url = 'discount_discount.php'
      const payload = {
        ids: this.action.selection,
        discount: this.model.discount
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDiscount = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    discountSpecialEntry () {
      this.loading = true
      var url = 'discount_special_discount.php'
      const payload = {
        ids: this.action.selection,
        discount_special: this.model.discount_special
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDiscountSpecial = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.company = ''
      this.model.segment = []
      axios.get('list_segments.php').then(response => {
        this.model.segment = response.data
      })
      this.model.consultant = []
      axios.get('list_consultants.php').then(response => {
        this.model.consultant = response.data
      })
      this.model.discount = '0,00'
      this.model.discount_special = '0,00'
      this.model.memo = ''
    }
  },
  provide: {
    grid: [Edit, Toolbar, Resize, Search, Sort, Group]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  switch (value.field) {
    case 'test':
      return value.key + ' ' + x
    default:
      // return value.field + ': ' + value.key + ' ' + x
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 13px;
}

.e-grid .status-column {
  padding-right: 0px !important;
  padding-left: 10px !important;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

#memoEditor {
  height: 100px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

.e-search-icon {
  font-size: 20px !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 295px !important;
}

.e-search input {
  font-size: 17px !important;
}

.table-header-icon {
  font-size: 14px !important;
}

.status-column .e-headercelldiv {
  padding-top: 3px !important;
  padding-left: 4px !important;
}

.status-column:last-child .e-headercelldiv {
  padding-top: 1px !important;
  padding-left: 7px !important;
  color: #1976d2;
}
</style>
