import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/components/dashboard/Dashboard'
import Accounts from '@/components/accounts/Accounts'
import Events from '@/components/events/Events'
import Participants from '@/components/participants/Participants'
import Associates from '@/components/associates/Associates'
import Groups from '@/components/groups/Groups'
import Settings from '@/components/settings/Settings'
import Products from '@/components/products/Products'
import Finishings from '@/components/finishings/Finishings'
import Incidentals from '@/components/incidentals/Incidentals'
import Discounts from '@/components/discounts/Discounts'
import Requests from '@/components/requests/Requests'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/accounts',
      name: 'Accounts',
      component: Accounts
    },
    {
      path: '/veranstaltungen',
      name: 'Veranstaltungen',
      component: Events
    },
    {
      path: '/teilnehmer',
      name: 'Teilnehmer',
      component: Participants
    },
    {
      path: '/mitarbeiter',
      name: 'Mitarbeiter (für die gewählte Veranstaltung)',
      component: Associates
    },
    {
      path: '/gruppen',
      name: 'Gruppen',
      component: Groups
    },
    {
      path: '/einstellungen',
      name: 'Einstellungen',
      component: Settings
    },
    {
      path: '/produkte',
      name: 'Produkte',
      component: Products
    },
    {
      path: '/werbeanbringungen',
      name: 'Werbeanbringungen',
      component: Finishings
    },
    {
      path: '/grundkosten',
      name: 'Grundkosten',
      component: Incidentals
    },
    {
      path: '/haendler',
      name: 'Händler (für die gewählte Veranstaltung)',
      component: Discounts
    },
    {
      path: '/anfragen',
      name: 'Anfragen',
      component: Requests
    }
  ]
})
